import { gql } from "@apollo/client";

const LEAGUE_MANAGER_LEAGUE = gql`
  query leaguesForDashboard($filter: ListLeagueInput) {
    leaguesForDashboard(filter: $filter) {
      title
      type
      status
      platform {
        id
        title
      }
      sports {
        id
        title
        icon
      }
      owner {
        federation {
          organizationName
          contactEmail
        }
      }
      email
      website
      id
      logo

      abbreviationLogo
      athlete {
        athlete {
          email
          dob
        }
      }
      tournaments {
        id
      }
    }
  }
`;

export default LEAGUE_MANAGER_LEAGUE;
