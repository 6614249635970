import { gql } from "@apollo/client";

const TOURNAMENT_CATEGORY_BY_ID = gql`
  query tournamentCategory($id: Int!) {
    tournamentCategory(id: $id) {
      name
      type
      gender
      order
      country
      discipline {
        id
      }
      enableMetrics
      team
      isAllowed
      moveToSingles
      singleCategory {
        id
      }
      isTeamSelection
      isClubSelection
      categoryEntries
      enableLicense
      status
      ageLimit
      ageType
      ageFrom
      ageGroup
      skillsLevel
      ageTo
      identifyTeamMember
      isDisplayRank
      minMembers
      maxMembers
      reservistsRequired
      minReservists
      maxReservists
      captainRequired
      countIndividualTotal
      countTeamTotal
      minAthletesToCount
      maxAthletesToCount
      nationalTournament
      clubChampionship
      countPointOnMatchResult
      skills
      askSkillLevel
      isDisplayCity
      isDisplayClub
      isDisplayBirthYear
      timeControl
      isMixedDoubles
      minDUPRSkillLevel
      maxDUPRSkillLevel

      thirdPartyLicense {
        id
      }

      id
      # mde
      # mds
      # mdwc
      # qe
      # qwc
      # qs
      # consolation
      categoryMen {
        id
        name
      }
      categoryWomen {
        id
        name
      }
      license {
        id
        license {
          title
          id
          # serviceFee
        }
      }
    }
  }
`;

export default TOURNAMENT_CATEGORY_BY_ID;
