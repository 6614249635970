import { gql } from "@apollo/client";

const LEAGUE_TOURNAMENTS = gql`
  query alltournament($filter: ListTournamentInput) {
    alltournament(filter: $filter) {
      tournaments {
        id
        title
        address
        startDate
        endDate
        country
        city
        coverPhoto
        withdrawlDeadline
        type
        status
        startDate
        endDate
        additionalQuestion {
          id
          question
        }
        tournamentCategory {
          id
          category {
            name
          }
          fee
        }
        league {
          league {
            title
            id
            sports {
              title
              icon
            }
          }
        }
        organizer {
          member {
            name
            surname
          }
        }
      }
      total
    }
  }
`;

export default LEAGUE_TOURNAMENTS;

// import { gql } from "@apollo/client";

// const LEAGUE_TOURNAMENTS = gql`
//   query alltournament($filter: ListTournamentInput) {
//     alltournament(filter: $filter) {
//       id
//       title
//       address
//       startDate
//       endDate
//       country
//       city
//       coverPhoto
//       withdrawlDeadline
//       type
//       status
//       additionalQuestion {
//         id
//         question
//       }
//       tournamentCategory {
//         id
//         category {
//           name
//         }
//         fee
//       }
//       organizer {
//         user {
//           name
//         }
//       }
//       referee {
//         referee {
//           name
//         }
//       }
//       additionalReferee {
//         referee {
//           name
//         }
//       }
//     }
//   }
// `;

// export default LEAGUE_TOURNAMENTS;
