import { gql } from "@apollo/client";

const LICENSE_BY_ID = gql`
  query license($id: Int!) {
    license(id: $id) {
      id
      title
      fee
      currency
      duration
      status
      startDate
      endDate
      tournatedLicenseFee
      partnerLicenseFee
      isManagerPayingServiceFee
      additionalQuestions {
        id
        question
        fieldType
        fieldOptions
        askEachAthlete
        isMandatory
      }
      leagues {
        id
        league {
          id
          title
          status
        }
      }
    }
  }
`;

export default LICENSE_BY_ID;
