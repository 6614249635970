import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation user($email: String!, $password: String!) {
    adminLogin(query: { email: $email, password: $password }) {
      id
      email
      accessToken
      name
      surname
      role
      user_sport
      isAuthenticationRequired
      isLeagueManager
      member_of_plateforms {
        platform {
          id
          title
        }
      }
    }
  }
`;

export default LOGIN;
