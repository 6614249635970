import { gql } from "@apollo/client";

const ME = gql`
  query {
    getProfile {
      email
      name
      surname
      role
      user_sport
      isLeagueManager
      friends {
        id
        name
        surname
        avatar
        email
      }
      member_of_plateforms {
        platform {
          id
          title
        }
      }
      id
    }
  }
`;

export default ME;
