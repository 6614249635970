import { gql } from "@apollo/client";

const PLATFORM_ATHLETES = gql`
  query (
    $filter: ListUsersInput
    $page: Int
    $limit: Int
    $platformId: Float # $gender: String, $nation: [String!], $sports: [Int!], $clubs: [Int!], $ageFrom: String, $ageTo: String
  ) {
    platformSpecificUsers(
      page: $page
      limit: $limit
      filter: $filter
      platformId: $platformId # nation: $nation, sports: $sports, clubs: $clubs, gender: $gender, ageFrom: $ageFrom, ageTo: $ageTo
    ) {
      users {
        id
        email
        name
        dob
        surname
        avatar
        nation
        gender
        phone
        city
        ipinId
        duprId
        teId
        ltsU10Id
        atpId
        wtaId
        fideId
        role
        additionalUserRole
        createdAt
      }
      total
    }
  }
`;

export default PLATFORM_ATHLETES;
