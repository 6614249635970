import { gql } from "@apollo/client";

const PLATFORM_BY_ID = gql`
  query organizationPlatformByFields($platformId: Int!) {
    organizationPlatformByFields(platformId: $platformId) {
      id
      federation {
        id
        organizationName
        organizationAbbreviation

        about
        contactEmail
        contactPhone
        website
        organizationType
        logo
        user {
          id
          email
          name
          surname
          instagram
          phone
        }
      }
      platform {
        id
        title
        platformType
        status
        country
        landingLink
        platformLink
        sports {
          id
          sport {
            id
            title
            icon
          }
          createdAt
        }
        disciplines {
          id
          title
        }
        thirdPartyLicenses {
          id
          title
          sports {
            id
            title
            icon
          }
          organizationName
          organizationAbbreviation
          type
        }
        customFee {
          id
          rangeStart
          rangeEnd
          feeType
          serviceType
          tournatedFee
          tournatedMinFee
          tournatedMaxFee
          partnerFee
          partnerMinFee
          partnerMaxFee
          currency
          isMonetization
          isManagerPayingServiceFee
        }
        signeeRole
        signeeFullName
        language
        timeZone
        currency
        description
        alreadyDomain
        primaryColor
        secondaryColor
        uploadFile
        yourRequest
        coverPhoto
        lightLogo
        darkLogo
        serviceProvider
        liveScoring
        liveStreaming
        onlineBookingCourts
        onlinePayments
        licenseMemberships
        onlinePayment
        needIntegration
        needLanding
        companyEmail
        fullAddress
        registrationNumber
        companyName
        domain
        mobileApp
        isEnablePrice
        smm
        tournatedEntryFee
        partnerEntryFee
        tournatedLicenseFee
        partnerLicenseFee
        graphicsForSmm
        isEnableMaintenance
        passcode
        maintenanceDescription
        maintenanceTitle
        template {
          id
        }
      }
    }
  }
`;

export default PLATFORM_BY_ID;
