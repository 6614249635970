import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import Login from "../pages/authentication/Login";
import AuthLayout from "./authentication/AuthLayout";
import { ReactSVG } from "react-svg";
import { Box } from "@material-ui/core";
import { jwtDecode } from "jwt-decode"; // Decode JWT token
import Cookies from "js-cookie";
import AuthenticationModal from "./widgets/modals/authenticationModal";

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState();
  // Function to check token expiration
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const { exp } = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convert to seconds
      return exp < currentTime;
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat invalid token as expired
    }
  };

  if (!auth.isAuthenticated || isTokenExpired(Cookies.get("token"))) {
    console.log("session expired");
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return (
      <AuthLayout>
        <Login setIsOpen={setIsOpen} setEmail={setEmail} />
        {isOpen && (
          <AuthenticationModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            setOtp={setOtp}
            otp={otp}
            email={email}
          />
        )}
      </AuthLayout>
    );
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
